import request from '@/utils/request'

/**
 * 授权的账号列表
 * @param data
 * @returns {*}
 */
export function http_admin_account_make_url(data) {
  return request({
    url: '/admin/account/make/url',
    method: 'post',
    data
  })
}
/**
 * 授权的账号列表
 * @param data
 * @returns {*}
 */
export function http_admin_account_list(data) {
  return request({
    url: '/admin/account/list',
    method: 'post',
    data
  })
}


/**
 * 添加授权。
 * @param data
 * @returns {*}
 */
export function http_admin_account_add_auth(data) {
  return request({
    url: '/admin/account/add/auth',
    method: 'post',
    data
  })
}


/**
 * 员工账号列表
 * @param data
 * @returns {*}
 */
export function http_admin_staff_list(data) {
  return request({
    url: '/admin/staff/list',
    method: 'post',
    data
  })
}


/**
 * 添加或者更新员工账号数据。
 * @param data
 * @returns {*}
 */
export function http_admin_staff_update(data) {
  return request({
    url: '/admin/staff/update',
    method: 'post',
    data
  })
}




/**
 * 删除员工账号信息。
 * @param data
 * @returns {*}
 */
export function http_admin_staff_delete(data) {
  return request({
    url: '/admin/staff/delete',
    method: 'post',
    data
  })
}









