import axios from 'axios'

import {
	Message
} from 'element-ui'


import {
	getToken
} from '@/utils/auth'


const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 5000
})

// request interceptor
service.interceptors.request.use(
	config => {

		if (getToken()) {
			config.headers['authorization'] = getToken()
		}
		return config
	},
	error => {
		console.log(error) // for debug
		return Promise.reject(error)
	}
)

service.interceptors.response.use(

	response => {
		const res = response.data
		if (res.code !== 2000) {
			Message({
				message: res.message,
				type: 'error',
				duration: 5 * 1000
			})

			if(res.code==4001){
				location.href = '/login';
				return ;
			}
			return res;
		}else {
			return res
		}
	},
	error => {
		console.log('err' + error) // for debug
		Message({
			message: error.message,
			type: 'error',
			duration: 5 * 1000
		})
		return Promise.reject(error)
	}
)

export default service
