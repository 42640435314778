<template>
  <div class="app">

    <!--  搜索工具  -->
    <div class="crm-tool">
      <div class="crm-tool-item">
        <el-input placeholder="请输入商家名称" size="mini" v-model="searchData.enterprise_name" clearable></el-input>
      </div>
      <div class="crm-tool-item">
        <el-button size="mini" @click="onSearchData" type="primary">搜索</el-button>
      </div>
      <div class="crm-tool-item-right">
        <el-button size="mini" @click="addAuth" type="primary">添加授权</el-button>
      </div>

    </div>

    <!--  表格数据  -->
    <div class="crm-table">
      <el-table :data="tableData" border size="mini" style="width: 100%">
        <el-table-column prop="organization_id" label="商家ID"></el-table-column>
        <el-table-column prop="enterprise_name" label="商家名称"></el-table-column>
        <el-table-column prop="create_timestamp" label="授权时间"></el-table-column>
        <el-table-column prop="expiration_timestamp" label="账号到期时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!--            <el-button type="danger" size="mini" @click="removeBinding(scope.row,scope.$index)">解除绑定</el-button>-->
            <el-button type="primary" size="mini" :disabled="scope.row.loading"
                       @click="loginCrm(scope.row,scope.$index)">
              <span v-if="scope.row.loading">跳转中 <i class="el-icon-loading"></i></span>
              <span v-else>进入crm</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>


    <div class="crm-footr">
      <el-pagination background layout="total, prev, pager, next"
                     :current-page="page"
                     :page-size="page_size"
                     :total="count"/>
    </div>


    <!--增加授权-->
    <div class="add_auth">
      <el-dialog title="添加授权" :close-on-click-modal="false" :visible.sync="dialogAuthVisible" width="600px">
        <div>
          <el-form ref="form" :model="authData" label-width="100px">
            <el-form-item label="商家Key">
              <el-input v-model="authData.key"></el-input>
            </el-form-item>
            <el-form-item label="商家Secret">
              <el-input v-model="authData.secret"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogAuthVisible = false">取 消</el-button>
          <el-button type="primary" @click="onSubmitAddAuth">确 定</el-button>
        </span>
      </el-dialog>
    </div>


  </div>
</template>

<script>


import {
  http_admin_account_list,
  http_admin_account_add_auth,
  http_admin_account_make_url
} from "@/api/admin/account.js"

export default {
  name: "list",
  data() {
    return {
      searchData: {
        enterprise_name: ""
      },
      tableData: [],
      loading: false,
      page: 1,
      page_size: 20,
      count: 0,
      dialogAuthVisible: false,
      authData: {
        key: "",
        secret: ""
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.on_reqeust_http_admin_account_list();
    })
  },
  methods: {
    on_reqeust_http_admin_account_list() {
      http_admin_account_list({page: this.page, page_size: this.page_size, search_data: this.searchData}).then(res => {
        if (res.code == 2000) {
          this.tableData = res.data.data;
          this.count = res.data.count;
        }
      })
    },
    /**
     * 单机搜索
     */
    onSearchData() {
      this.page = 1;
      this.on_reqeust_http_admin_account_list();
    },
    /**
     * 移除账号授权
     * @param row
     * @param index
     */
    removeBinding(row, index) {
      console.log(row, index);
      let _this = this;
      _this.$prompt('请输入账号密码', '解除账号绑定安全认证', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: "password",
        closeOnClickModal: false,
        inputErrorMessage: '密码不正确',
        confirmButtonClass: 'btn-custom-cancel',
        cancelButtonClass: 'btn-custom-cancel',
        inputValidator: (value) => {
          if (value == null) {
            return "密码不能为空";
          } else {
            return true;
          }
        },
      }).then((args) => {

        let value = args.value;
        console.log(value);
        _this.$message.success("解除成功");
        _this.tableData.splice(index, 1);

      }).catch(() => {

      });
    },

    //添加授权。
    addAuth() {
      this.dialogAuthVisible = true;
    },
    onSubmitAddAuth() {
      if (this.authData.key.length < 10 || this.authData.secret.length < 10) {
        this.$message.error("您的key和secret格式不对");
        return false;
      }
      http_admin_account_add_auth(this.authData).then(res => {
        if (res.code == 2000) {
          this.tableData.unshift(res.data);
          this.dialogAuthVisible = false;
          this.authData.key = "";
          this.authData.secret = "";
        }
      })
    },
    /**
     * 跳转到crm系统。
     */
    loginCrm(row, index) {
      console.log(row, index);
      row.loading = true;
      http_admin_account_make_url(row).then(res => {
        if (res.code == 2000) {
          row.loading = false;
          window.open(res.data.url,"_blank");
        }
      })
    },


  }
}
</script>

<style lang="scss" scoped>

.app {
  .crm-tool {
    height: 50px;
    line-height: 50px;

    .crm-tool-item {
      height: 40px;
      line-height: 40px;
      float: left;
      margin: 10px 10px 5px 10px;
    }

    .crm-tool-item-right {
      height: 40px;
      line-height: 40px;
      float: right;
      margin: 10px 10px 5px 10px;
    }

  }

  .crm-table {
    padding: 10px;
  }

}

</style>