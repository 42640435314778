<template>
  <div class="app">
    <div class="crm-tool">
      <div class="crm-tool-item">
        <el-input v-model="searchData.staff_data" size="mini" style="width: 320px;" placeholder="输入员工信息查询">
          <el-select v-model="searchData.staff_type" slot="prepend" style="width: 100px;" placeholder="请选择">
            <el-option label="姓名" value="user_name"></el-option>
            <el-option label="登录手机" value="login_account"></el-option>
            <el-option label="登录邮箱" value="email"></el-option>
          </el-select>
        </el-input>
      </div>
      <div class="crm-tool-item">
        <el-select v-model="searchData.staff_status" size="mini" clearable placeholder="状态">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="crm-tool-item">
        <el-button size="mini" type="primary" @click="onSearch">搜索</el-button>
      </div>

      <div class="crm-tool-item-right">
        <el-button size="mini" type="primary" @click="handleClickCreate">
          <i class="el-icon-circle-plus-outline"></i>添加员工信息
        </el-button>
      </div>
    </div>

    <div class="crm-table">
      <el-table :data="tableStaffData" size="mini" border style="width: 100%">
        <el-table-column prop="staff_id" label="员工ID" width="100"></el-table-column>
        <el-table-column prop="user_name" label="员工姓名"></el-table-column>
        <el-table-column prop="login_phone" label="员工号码"></el-table-column>
        <el-table-column prop="user_mail" label="员工邮箱"></el-table-column>
        <el-table-column prop="role_name" label="员工角色"></el-table-column>
        <el-table-column prop="wechat_nickname" label="微信昵称"></el-table-column>
        <el-table-column prop="user_status_name" label="员工状态"></el-table-column>
        <el-table-column prop="create_timestamp" label="创建时间"></el-table-column>
        <el-table-column prop="update_timestamp" label="修改时间"></el-table-column>
        <el-table-column width="120" label="操作">
          <template #default="scope">
            <el-button type="text" size="small" @click="handleClickUpdate(scope.row,scope.$index)">更新</el-button>
            <el-button type="text" size="small" @click="handleClickDelete(scope.row,scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="footer">
      <el-pagination size="mini" background layout="total, prev, pager, next" :total="count"></el-pagination>
    </div>


    <div class="crm-staff-update">
      <el-dialog :title="dialogUpdateStaffTitle" :close-on-click-modal="false" :visible.sync="dialogUpdateStaffVisible"
                 width="600px">
        <div style="width: 450px;margin: 0 50px;">
          <el-form ref="form" :model="staffInfo" label-width="80px">
            <el-form-item label="员工姓名">
              <el-input v-model="staffInfo.user_name"></el-input>
            </el-form-item>
            <el-form-item label="员工号码">
              <el-input v-model="staffInfo.login_phone"></el-input>
            </el-form-item>
            <el-form-item label="员工邮箱">
              <el-input v-model="staffInfo.user_mail"></el-input>
            </el-form-item>
            <el-form-item label="登录密码">
              <el-input v-model="auto_passwore" v-show="false" type="password" autocomplete="off"></el-input>
              <el-input v-model="staffInfo.login_passwd" type="password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码">
              <el-input type="password" v-model="staffInfo.login_check_pass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="员工角色">
              <el-select v-model="staffInfo.role" style="width: 100%;" placeholder="请选择">
                <el-option
                    v-for="item in role_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工状态">
              <el-radio-group v-model="staffInfo.user_status">
                <el-radio label="NORMAL">正常</el-radio>
                <el-radio label="RESIGN">离职</el-radio>
                <el-radio label="STOP">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogUpdateStaffVisible = false">取 消</el-button>
          <el-button type="primary" @click="onSubmitData">确 定</el-button>
        </span>
      </el-dialog>
    </div>

  </div>


</template>

<script>
import {http_admin_staff_list, http_admin_staff_update,http_admin_staff_delete} from "@/api/admin/account.js";

export default {
  name: "staff_manage",
  data() {
    return {
      tableStaffData: [],
      count: 0,
      page: 1,
      page_size: 20,
      options: [
        {label: "在职", value: 'NORMAL'},
        {label: "暂停", value: 'STOP'},
        {label: "离职", value: 'RESIGN'},
      ],
      searchData: {
        staff_type: "user_name",
        staff_data: "",
        staff_status: ""
      },
      dialogUpdateStaffVisible: false,
      dialogUpdateStaffTitle: "创建员工信息",
      auto_passwore: "",
      staffInfo: {
        staff_id: 0,
        user_name: "",
        login_phone: "",
        user_mail: "",
        login_passwd: "",
        login_check_pass: "",
        user_status: 'NORMAL',
        role: "ordinary"
      },
      staffIndex: -1,
      role_options: [
        {label: "普通员工", value: "ORDINARY"},
        {label: "管理员", value: "ADMIN"},
      ]
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.on_request_http_admin_staff_list();
    })
  },
  methods: {
    init_staff_info() {
      this.staffInfo = {
        staff_id: 0,
        user_name: "",
        login_phone: "",
        user_mail: "",
        login_passwd: "",
        login_check_pass: "",
        user_status: 'NORMAL',
        role: "ordinary"
      };
      this.staffIndex = -1;
    },
    on_request_http_admin_staff_list() {

      let search_data = this.searchData;
      let page = this.page;
      let page_size = this.page_size;
      http_admin_staff_list({page, page_size, search_data}).then(res => {
        if (res.code == 2000) {
          this.tableStaffData = res.data.data;
          this.count = res.data.count;
        }
      })

    },
    onSearch() {
      this.on_request_http_admin_staff_list();
    },
    handleClickCreate() {
      this.init_staff_info();
      this.dialogUpdateStaffVisible = true;
    },
    handleClickUpdate(row, index) {
      this.init_staff_info();
      let data = JSON.parse(JSON.stringify(row));
      this.staffInfo = data;
      this.staffIndex = index;
      this.dialogUpdateStaffVisible = true;
    },
    handleClickDelete(row, index) {
      console.log(row);
      console.log(index);
      let _this = this;
      this.$confirm(
          '确定永久删除该员工信息？',
          'Warning',
          {
            confirmButtonText: '确定',
            cancelButtonText: '还没有想好',
            type: 'warning',
          }
      ).then(() => {
        http_admin_staff_delete(row).then(res=>{
          if(res.code==2000){
            _this.tableStaffData.splice(index,1);
          }
        })
      }).catch(() => { })

    },
    onSubmitData() {
      console.log(this.staffInfo);
      if (this.staffInfo.login_passwd.length > 0) {
        if (this.staffInfo.login_passwd.length < 6) {
          this.$message.error("密码不能为空");
          return false;
        }
        if (this.staffInfo.login_passwd != this.staffInfo.login_check_pass) {
          this.$message.error("两次密码不一样");
          return false;
        }
      }

      http_admin_staff_update(this.staffInfo).then(res => {
        let _this = this;
        if (res.code == 2000) {
          _this.dialogUpdateStaffVisible = false;
          if (_this.staffInfo.staff_id == 0) {
            _this.tableStaffData.unshift(res.data);
          } else {
            _this.$set(_this.tableStaffData, _this.staffIndex, res.data);
          }
        }
        _this.init_staff_info();
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.app {

  .crm-tool {
    margin-top: 10px;
    height: 50px;
    line-height: 50px;
    padding-left: 10px;

    .crm-tool-item {
      height: 40px;
      line-height: 40px;
      float: left;
      margin: 10px 10px 5px 0px;
    }

    .crm-tool-item-right {
      height: 40px;
      line-height: 40px;
      float: right;
      margin: 10px 10px 5px 0px;
    }

  }

  .crm-table {
    padding: 10px;
  }

  .footer {
    text-align: right;
    margin-top: 20px;
  }

  .crm-staff-update {

  }

}
</style>