import request from '@/utils/request'

/**
 * 登录系统
 * @param data
 * @returns {*}
 */
export function http_auth_login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  })
}


