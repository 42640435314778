<template>
  <div class="user-info">
    <div class="user-info-form">
      <el-form ref="userInfoData" :model="userInfoData" label-width="80px">

        <el-form-item label="旧密码">
          <el-input v-model="userInfoData.oldpasswd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="userInfoData.passwd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="重复密码">
          <el-input v-model="userInfoData.checpasswd" type="password"></el-input>
        </el-form-item>

        <el-form-item label="">
          <el-button type="primary">提交更改</el-button>
        </el-form-item>

      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "passwd",
  data() {
    return {
      userInfoData: {
        oldpasswd: "",
        passwd: "",
        checpasswd: ""
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  padding: 20px;
  width: 500px;

  .user-info-form {
    width: 100%;
  }
}
</style>