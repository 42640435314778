<template>
  <div class="login">
    <div class="header">
      <div class="logo">
        <!--        <img src="@/assets/logo.png" />-->
        <div style="color: #e0e0e0;font-size: 32px;width: 300px;margin-left: 20px;margin-top: 8px;">蜜蜂到店 CRM
          <small style="font-size:14px;">运营商版</small>
        </div>
      </div>
      <div class="nav">
        <!--        <ul>-->
        <!--          <li>-->
        <!--            <a href="/">官网</a>-->
        <!--          </li>-->
        <!--          <li>-->
        <!--            <a href="javascript:void(0)" @click="routPush('/help')">帮助文档</a>-->
        <!--          </li>-->
        <!--          <li>-->
        <!--            <el-button type="primary" size="small" @click="login">登录系统</el-button>-->
        <!--          </li>-->
        <!--        </ul>-->
      </div>
    </div>
    <div class="login-body">
      <div class="title">登录运营商系统</div>
      <div class="login-form">
        <el-form ref="form" :model="form" label-width="0">
          <el-form-item label="">
            <el-input prefix-icon="el-icon-user" placeholder="登录账号" v-model="form.login_username"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input prefix-icon="el-icon-lock" placeholder="登录密码" type="password"
                      v-model="form.login_password"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button style="width: 100%;" type="primary" :disabled="loginStatus" @click="loginSystem">
              {{ loginText }} <i v-if="loginStatus" class="el-icon-loading"></i>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="other-login">
        <el-button type="text">微信登录</el-button>
      </div>
      <div class="footer">
        &copy;2016-2021长沙即刻到店网络科技有限公司
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import {http_auth_login} from "@/api/auth.js"
import {setToken} from "@/utils/auth.js"

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      form: {
        login_username: "18108447675",
        login_password: "123456"
      },
      loginStatus: false,
      loginText: "登录系统",
      preg_phone: /^1[3|4|5|6|7|8|9]\d{9}$/
    }
  },
  methods: {
    loginSystem() {

      let _this = this;

      if (!this.preg_phone.test(this.form.login_username)) {
        this.$message.error("登录手机号码不合法");
        return false;
      }
      if(this.form.login_password.length<6){
        this.$message.error("登录密码长度不能少于6位");
        return false;
      }

      this.loginStatus = true;
      this.loginText = "登录中....";
      http_auth_login(this.form).then(res => {
        if(res.code==2000){
          setToken(res.data.token);
        }
        _this.loginStatus = false;
        _this.loginText = "登录系统";
        _this.$router.push("/admin");
        console.log(res);
      })

    }
  }
}
</script>
<style lang="scss" scoped>
.login {
  height: 100%;
  background-color: #2d3a4b;

  .header {
    height: 55px;
    //background-color: #ffffff;
    background-color: #2d3a4b;
    width: 100%;
    border-bottom: 1px solid rgba(204, 204, 204, .16);
    //box-shadow: 0px 1px 3px 0px rgba($color: #abcdef, $alpha: 0.2);
    min-width: 800px;
    //background-color: red;

    .logo {
      float: left;

      img {
        width: 180px;
        margin-left: 20px;
      }
    }

    .nav {
      float: right;
      height: 55px;
      line-height: 55px;

      ul,
      li {
        list-style-type: none;
        float: left;
        margin-right: 40px;
      }
    }
  }


  .login-body {
    width: 500px;
    position: absolute;
    left: -250px;
    margin-left: 50%;
    margin-top: 8%;

    .title {
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-weight: 600;
      font-size: 28px;
      margin-bottom: 20px;
      color: #ffffff;
    }

    .login-form {

    }

    .other-login {
      text-align: right;
    }

    .footer {
      position: absolute;
      margin-top: 40px;
      text-align: center;
      color: #b4b4b4;
      width: 500px;
      font-size: 14px;
    }
  }
}

</style>
