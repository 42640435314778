<template>
  <div class="main">
      <div class="main-text">欢迎来到蜜蜂到店crm运营商中心。。。。</div>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style lang="scss" scoped>
  .main{
    .main-text{
      width: 600px;
      margin: 200px auto;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
    }
  }
</style>