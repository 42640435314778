<template>
  <div class="app">


    <!--  搜索工具  -->
    <div class="crm-tool">
      <div class="crm-tool-item">
        <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="searchData.date_field"
            size="mini"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="crm-tool-item">
        <el-input placeholder="请输入商家名称" size="mini" v-model="searchData.input" clearable></el-input>
      </div>
      <div class="crm-tool-item">
        <el-button size="mini" type="primary">搜索</el-button>
      </div>
    </div>


    <!--  表格数据  -->
    <div class="crm-table">
      <el-table :data="tableData" border size="mini" style="width: 100%">
        <el-table-column prop="date" label="日期"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="name" label="新增线索"></el-table-column>
        <el-table-column prop="name" label="无效线索"></el-table-column>
        <el-table-column prop="name" label="有效线索"></el-table-column>
        <el-table-column prop="name" label="有效客资"></el-table-column>
        <el-table-column prop="name" label="流失客资"></el-table-column>
        <el-table-column prop="name" label="新增订单"></el-table-column>
        <el-table-column prop="name" label="订单业绩"></el-table-column>
        <el-table-column prop="name" label="订单实收"></el-table-column>
        <el-table-column prop="name" label="创建时间"></el-table-column>
        <el-table-column prop="name" label="更新时间"></el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
export default {
  name: "list",
  data() {
    return {
      searchData: {},
      tableData: [],
    }
  }
}
</script>

<style lang="scss" scoped>
.app {
  .crm-tool {
    //margin-top: 10px;
    height: 50px;
    line-height: 50px;

    .crm-tool-item {
      height: 40px;
      line-height: 40px;
      float: left;
      margin: 10px 10px 5px 10px;
    }
  }

  .crm-table {
    padding: 10px;
  }
}
</style>