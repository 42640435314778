<template>
  <div class="user-info">
    <div class="user-info-form">
      <el-form ref="userInfoData" :model="userInfoData" label-width="80px">
        <el-form-item label="姓名">
          <el-input v-model="userInfoData.name"></el-input>
        </el-form-item>
        <el-form-item label="登录手机">
          <el-input v-model="userInfoData.login_phone" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="登录邮箱">
          <el-input v-model="userInfoData.login_email" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-input v-model="userInfoData.role" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="微信绑定">
          <el-input v-model="userInfoData.wechat_bind" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary">提交更改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "userinfo",
  data() {
    return {
      userInfoData: {
        name: "",
        login_phone: "",
        login_email: "",
        role: "操作员",
        wechat_bind: "已绑定"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  padding: 20px;
  width: 500px;

  .user-info-form {
    width: 100%;
  }
}
</style>