<template>
  <div class="app">
    <div class="crm-table">
      <el-table :data="tableData" size="mini" border style="width: 100%">
        <el-table-column prop="date" label="日期" width="180"></el-table-column>
        <el-table-column prop="name" label="员工姓名" width="160"></el-table-column>
        <el-table-column prop="name" label="操作类型" width="140"></el-table-column>
        <el-table-column prop="name" label="操作内容"></el-table-column>
        <el-table-column prop="name" label="IP地址" width="140"></el-table-column>
        <el-table-column prop="name" label="IP地域" width="240"></el-table-column>
        <el-table-column prop="address" label="操作时间" width="180"></el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination background layout="total, prev, pager, next" :total="count"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      tableData: [],
      count: 100
    }
  }
}
</script>

<style lang="scss" scoped>
.app {
  .crm-table {
    margin: 10px;
  }

  .footer {
    text-align: right;
    margin-top: 20px;
  }
}
</style>