<template>
  <div class="user-info">
    <div class="user-info-form">
      <el-form ref="userInfoData" :model="userInfoData" label-width="80px">

        <el-form-item label="新的邮箱">
          <el-input v-model="userInfoData.email"></el-input>
        </el-form-item>
        <el-form-item label="账号密码">
          <el-input v-model="userInfoData.passwd" type="password"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="updateEmailInfo">提交更改</el-button>
        </el-form-item>

      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "loginEmail",
  data() {
    return {
      userInfoData: {
        email: "",
      }
    }
  },
  methods:{
    updateEmailInfo(){
      this.$message.success("已经发送成功，请在新的邮箱里面验证,有效时间24小时");
    }
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  padding: 20px;
  width: 500px;

  .user-info-form {
    width: 100%;

    .info-item {
      float: left;
    }

    .info-item-right {
      margin-left: 20px;
    }
  }
}
</style>