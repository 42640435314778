import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/Admin.vue'),
    redirect:"/admin/index",
    children: [
      {
        path: "/admin/index",
        name: "admin_index",
        component: () => import('@/components/main/index')
      },
      {
        path: "/admin/analysis/account",
        name: "analysis_account",
        component: () => import('@/components/analysis/account/list')
      },
      {
        path: "/admin/analysis/statistics",
        name: "analysis_statistics",
        component: () => import('@/components/analysis/statistics/list')
      },
      {
        path: "/admin/authorization/manage",
        name: "authorization_manage",
        component: () => import('@/components/authorization/manage/list')
      },
      {
        path: "/admin/staff/manage",
        name: "staff_manage",
        component: () => import('@/components/staff/manage/list')
      },
      {
        path: "/admin/log/login",
        name: "log_login",
        component: () => import('@/components/log/login')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
