<template>
  <div class="user-info">
    <div class="user-info-form">
      <el-form ref="userInfoData" :model="userInfoData" label-width="80px">

        <el-form-item label="新的号码">
          <el-input v-model="userInfoData.passwd"></el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <div class="info-item" style="width: 280px;">
            <el-input v-model="userInfoData.oldpasswd"></el-input>
          </div>
          <div class="info-item info-item-right" style="width: 120px;">
            <el-button  style="width: 100%;">发送验证码</el-button>
          </div>
        </el-form-item>
        <el-form-item label="账号密码">
          <el-input v-model="userInfoData.passwd" type="password"></el-input>
        </el-form-item>

        <el-form-item label="">
          <el-button type="primary">提交更改</el-button>
        </el-form-item>

      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "loginPhone",
  data() {
    return {
      userInfoData: {
        oldpasswd: "",
        passwd: "",
        checpasswd: ""
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  padding: 20px;
  width: 500px;

  .user-info-form {
    width: 100%;
    .info-item{
      float: left;
    }
    .info-item-right{
      margin-left: 20px;
    }
  }
}
</style>