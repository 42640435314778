<template>
  <div class="wechat">

    <div class="wechat-yes-bing" v-if="bing_status">

      <el-form ref="form"  label-width="80px">
        <el-form-item label="昵称">
          出门右拐是食堂
        </el-form-item>
        <el-form-item label="性别">
          男
        </el-form-item>
        <el-form-item label="地址">
          中国 - 湖南省 - 长沙市
        </el-form-item>
        <el-form-item label="操作">
          <el-button type="danger" size="mini">解绑微信登录</el-button>
        </el-form-item>
      </el-form>

    </div>

    <div class="wechat-no-bing" v-else>
      <el-form ref="form"  label-width="80px">
        <el-form-item label="操作">
          <el-button type="primary" size="mini">绑定微信登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "wechat",
  data() {
    return {
      bing_status: true
    }
  }
}
</script>

<style lang="scss" scoped>
.wechat {
  .wechat-no-bing {
    margin-top: 20px;
  }
  .wechat-yes-bing{
    margin-top: 20px;
  }
}
</style>