<template>
  <div class="app">


    <!--  搜索工具  -->
    <div class="crm-tool">
      <div class="crm-tool-item">
        <el-input placeholder="请输入商家名称" size="mini" v-model="searchData.enterprise_name" clearable></el-input>
      </div>
      <div class="crm-tool-item">
        <el-button size="mini" @click="onSearchData" type="primary">搜索</el-button>
      </div>

    </div>


    <!--  表格数据  -->
    <div class="crm-table">
      <el-table :data="tableData" border size="mini" style="width: 100%">
        <el-table-column prop="organization_id" label="商家ID"></el-table-column>
        <el-table-column prop="enterprise_name" label="商家名称"></el-table-column>
        <el-table-column prop="create_timestamp" label="授权时间"></el-table-column>
        <el-table-column prop="expiration_timestamp" label="账号到期时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!--            <el-button type="danger" size="mini" @click="removeBinding(scope.row,scope.$index)">解除绑定</el-button>-->
            <el-button type="primary" size="mini" :disabled="scope.row.loading" @click="loginCrm(scope.row,scope.$index)">
              <span v-if="scope.row.loading">跳转中 <i class="el-icon-loading"></i></span>
              <span v-else>进入crm</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>


    <div class="crm-footr">
      <el-pagination background layout="total, prev, pager, next"
                     :current-page="page"
                     :page-size="page_size"
                     :total="count"/>
    </div>


  </div>
</template>

<script>

import {http_admin_account_list} from "@/api/admin/account.js"

export default {
  name: "list",
  data() {
    return {
      searchData: {
        enterprise_name: ""
      },
      tableData: [],
      loading: false,
      page: 1,
      page_size: 20,
      count: 0
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.on_reqeust_http_admin_account_list();
    })
  },
  methods: {
    on_reqeust_http_admin_account_list() {
      http_admin_account_list({page: this.page, page_size: this.page_size, search_data: this.searchData}).then(res => {
        if (res.code == 2000) {
          this.tableData = res.data.data;
          this.count = res.data.count;
        }
      })
    },
    /**
     * 单机搜索
     */
    onSearchData(){
      this.page = 1;
      this.on_reqeust_http_admin_account_list();
    },
    /**
     * 显示授权日志
     */
    loginCrm(row, index) {
      console.log(row, index);
      row.loading = true;
      setTimeout(function () {
        row.loading = false;
      }, 2000)
    },


  }
}
</script>

<style lang="scss" scoped>

.app {
  .crm-tool {
    height: 50px;
    line-height: 50px;

    .crm-tool-item {
      height: 40px;
      line-height: 40px;
      float: left;
      margin: 10px 10px 5px 10px;
    }

  }

  .crm-table {
    padding: 10px;
  }

  .crm-footr {
    margin: 10px;
  }

}

</style>